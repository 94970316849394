<style lang="less">
  .wrap-data-form {
    .fm-tree {
      width: 100%;
      overflow: auto;
      padding-bottom: 48px;
      box-sizing: border-box;
      background: #FFF;
      .fm-tree-search {
        padding-bottom: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: #FFF;
        input {
          width: 100%;
        }
      }
    }
    .fm-tree-node .fm-tree-node {
      padding-right: 0;
      padding-left: 5px;
    }
    .org-label, .org-label .p_label {
      height: auto;
    }
    .org-label-btn {
      display: inline-flex !important;
    }
  }
</style>

<template>
  <page-base class="wrap-data-form" :show-btn="true" @save="save(null)" @submit="submit">
    <worker-img class="header-zjz" :worker-id="workerId"></worker-img>
    <div class="form-block-img">
      <data-form
        :need-del="false"
        :source-data="sourceData"
        :draft-data="formData"
        :form-parms="formParms"
        @formChange="formChange"
        :wait-data="waitData"
        :is-absolut="true"
        :wait-keys="waitKeys">
      </data-form>
    </div>
  </page-base>
</template>

<script>
import DataForm from './dataForm'
import PageBase from './pageBase'
import WorkerImg from './workerImg'
// import OrgSelect from '@/views/org/org/orgSelect'
// import OrgMultipleSelect from '@/views/org/org/orgMultipleSelect'

import {
  dataAppleRequest,
  workerRequest
} from '../../../api'

export default {
  components: {
    DataForm,
    PageBase,
    WorkerImg
  },
  async created () {
    // await this.$store.dispatch('loadPositionList')
    // await this.$store.dispatch('loadOrgTypeList')
  },
  activated () {
    this.workerId = this.$router.history.current.query.workerId
    this.loadData()
  },
  data () {
    return {
      formData: {},
      formCheck: true,
      changeData: {},
      isChange: false,
      dealSourceData: {},
      data: {},
      workerId: null
    }
  },
  computed: {
    sourceData () {
      return this.data && this.data.sourceInfo ? this.data.sourceInfo : null
    },
    draftData () {
      let data = this.data ? this.data.editInfo || this.data.sourceInfo : null
      if (data) {
        data.maritalStatus = this.sourceData ? this.sourceData.maritalStatus : null
      }
      return data
    },
    waitData () {
      return this.data && this.data.modifyApplyInfo ? this.data.modifyApplyInfo : null
    },
    waitKeys () {
      return this.data && this.data.modifyApply && this.data.modifyApply.newData ? Object.keys(JSON.parse(this.data.modifyApply.newData)) : []
    },
    formParms () {
      let data1 = [{
        type: 'input',
        label: '工号',
        key: 'code'
      },
      {
        type: 'input',
        label: '机构名称',
        key: 'orgName',
        disabled: this.orgNameDisabled
      },
      {
        type: 'input',
        label: '机构名称2',
        show: () => {
          return this.needOrgName2
        },
        key: 'zg',
        disabled: this.orgName2Disabled
      },
      // {
      //   type: 'cmp',
      //   label: '所属职务',
      //   key: 'positionId',
      //   cmp: OrgSelect,
      //   format: (data) => {
      //     let data1 = this.positionList.find(v => v.key === data.positionId)
      //     return data1 ? data1.label : null
      //   },
      //   compare: (data) => {
      //     return data.positionId
      //   }
      // },
      // {
      //   type: 'cmp',
      //   cmp: OrgMultipleSelect,
      //   label: '兼职职务',
      //   key: 'positionIds',
      //   format: (data) => {
      //     return Array.isArray(data.positionDatas) ? data.positionDatas.map(v => v.name).join(',') : ''
      //   },
      //   compare: (data) => {
      //     return Array.isArray(data.positionIds) ? data.positionIds.join(',') : data.positionIds
      //   }
      // },
      // {
      //   type: 'select',
      //   label: '默认展示机构等级',
      //   key: 'zhuanzhi',
      //   columns: this.orgTypeList.map(v => {return {value: 'orgTypeValue_' + v.data.id, label: v.data.name}}),
      //   format: (data) => {
      //     let fData = this.orgTypeList.map(v => {return {key: 'orgTypeValue_' + v.data.id, label: v.data.name}}).find(v => v.key === data.zhuanzhi)
      //     return fData ? fData.label : null
      //   }
      // },
      {
        type: 'select',
        label: '是否在编',
        key: 'type',
        columns: [{value: '在编', label: '在编'}, {value: '非在编', label: '非在编'}]
      },
      {
        type: 'select',
        label: '在岗状态',
        key: 'jobTypeC',
        columns: this.workerZgStatus.map(v => ({value: v.key, label: v.label}))
      },
      {
        type: 'input',
        label: '工龄',
        key: 'maritalStatus',
        disabled: true
      },
      {
        type: 'datePicker',
        label: '参加工作时间',
        key: 'correctionDate',
        format: (data) => {
          return data.correctionDate ? this.$datetime.format(data.correctionDate, 'Y-M-D') : null
        }
      },
      {
        type: 'datePicker',
        label: '进入本单位时间',
        key: 'entryDate',
        format: (data) => {
          return data.entryDate ? this.$datetime.format(data.entryDate, 'Y-M-D') : null
        }
      },
      {
        type: 'select',
        label: '人员类别',
        key: 'jobType',
        columns: this.workerJobTypes.map(v => ({value: v.key, label: v.label}))
      },
      {
        type: 'select',
        label: '入职方式',
        key: 'employmentForm',
        columns: this.employmentForms.map(v => ({value: v.key, label: v.label}))
      },
      {
        type: 'select',
        label: '离职方式',
        key: 'discipline',
        disabled: this.workerApplyEditType1,
        columns: this.$store.getters.selectDatas['worker_resign_list'].map(v => ({value: v.key, label: v.label}))
      },
      {
        type: 'select',
        label: '是否专业技术人员',
        key: 'address',
        columns: [{value: '是', label: '是'}, {value: '否', label: '否'}]
      },
      {
        type: 'input',
        label: '专业技术人员专业名称',
        key: 'shuangjiantiao',
        show: (data) => {
          return data.address === '是'
        }
      },
      {
        type: 'select',
        label: '专业技术人员专业类别',
        key: 'email',
        show: (data) => {
          return data.address === '是'
        },
        columns: this.zyjsryzylb.map(v => ({value: v.key, label: v.label}))
      },
      {
        type: 'select',
        label: '是否卫生专业技术人员',
        key: 'jobTitle',
        show: (data) => {
          return data.address === '是'
        },
        columns: [{value: '是', label: '是'}, {value: '否', label: '否'}]
      }]
      if (!this.workerShowDefaultOrg) {
        data1 = data1.filter(v => v.key !== 'zhuanzhi')
      }
      return data1
    },
    workerApplyEditType1 () {
      return this.$store.getters.sysConfig['workerApplyEditType1']
    },
    employmentForms () {
      return this.$store.getters.selectDatas['employment_form']
    },
    zyjsryzylb () {
      return this.$store.getters.selectDatas['zyjsryzylb']
    },
    workerJobTypes () {
      return this.$store.getters.selectDatas['worker_job_type']
    },
    workerZgStatus () {
      return this.$store.getters.selectDatas['worker_zg_status']
    },
    // positionList () {
    //   return this.$store.getters.positionList
    // },
    // orgTypeList () {
    //   return this.$store.getters.orgTypeList
    // },
    // orgTypeIds () {
    //   return this.$store.getters.orgTypeList.map(v => v.data.id)
    // },
    orgNameDisabled () {
      return this.$store.getters.sysConfig['orgNameDisabled']
    },
    orgName2Disabled () {
      return this.$store.getters.sysConfig['orgName2Disabled']
    },
    needOrgName2 () {
      return this.$store.getters.sysConfig['needOrgName2']
    },
    workerShowDefaultOrg () {
      return this.$store.getters.sysConfig['workerShowDefaultOrg']
    },
    completeness () {
      if (!this.formData) {
        return 0
      }
      return this.formParms.filter(v => v.type !== 'null' && this.formData[v.key]).length / this.formParms.filter(v => v.type !== 'null').length
    }
  },
  watch: {
    completeness: {
      handler (data) {
        this.$emit('completeness', data)
      },
      immediate: true,
      deep: true
    },
    draftData: {
      handler () {
        this.updateFormData()
      },
      immediate: true,
      deep: true
    },
    sourceData: {
      handler () {
        this.updateSourceData()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async loadData () {
      if (this.workerId) {
        let data = await workerRequest.getEdit({
          workerId: this.workerId
        })
        if (data.length !== 1) {
          this.$notice.info({
            title: '系统提示',
            desc: '数据异常，请联系管理员'
          })
        } else {
          this.data = data[0]
        }
      }
    },
    updateSourceData () {
      let data1 = JSON.parse(JSON.stringify(this.sourceData))
      if (data1 && data1.positionIds && !Array.isArray(data1.positionIds)) {
        data1.positionIds = data1.positionIds.split(',').map(v => Number(v))
      }
      this.dealSourceData = data1
    },
    updateFormData () {
      let data1 = this.draftData ? JSON.parse(JSON.stringify(this.draftData)) : {}
      if (data1) {
        if (data1.positionIds && !Array.isArray(data1.positionIds)) {
          data1.positionIds = data1.positionIds.split(',').map(v => Number(v))
        } else {
          data1.positionIds = []
        }
      }
      this.formData = data1
    },
    testEdited () {
      return this.isChange
    },
    async save (type) {
      if (!this.formCheck) {
        this.$notice.info({
          title: '系统提示',
          desc: '表单验证未通过'
        })
        throw new Error('表单验证未通过')
      }
      let saveData = JSON.parse(JSON.stringify(this.formData))
      if (saveData && Array.isArray(saveData.positionIds)) {
        saveData.positionIds = saveData.positionIds.join(',')
      }
      await workerRequest.saveEdit(Object.assign({}, this.formData, {
        dataId: this.formData.id,
        id: undefined
      }))
      this.isChange = false
      if (type !== 'submit') {
        this.loadData()
        this.$notice.info({
          title: '系统提示',
          desc: '保存成功'
        })
      }
      return true
    },
    async submit () {
      let data = await this.save('submit')
      if (data) {
        await dataAppleRequest.multipleSubmit(this.workerId, {
          dataType: 'worker'
        })
        this.$notice.info({
          title: '系统提示',
          desc: '提交成功'
        })
        this.loadData()
      }
    },
    formChange (data) {
      this.isChange = true
      this.formData = data.data
      this.formCheck = data.pass
    }
  }
}
</script>

<style lang="less" scope>
.form-block-img {
  width: calc(100% - 50px);
  height: calc(100% - 160px);
  padding: 18px 25px 0;
}
.header-zjz {
  margin-top: 40px;
}
</style>